import { useInput } from '@capturi/react-utils'
import { Button, Flex, Input, Select } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useState } from 'react'
import Widget from './Widget'
import ErrorComponent from './components/ErrorComponent'

const TestLayer: React.FC = () => {
  const url = new URL(window.location.toString())

  const params = url.searchParams
  const [input, setInput] = useInput({
    customer: params.get('customer') || params.get('customers') || '',
  })
  const token = params.get('token')
  const sessionId = params.get('sessionId')

  const [state, setState] = useState(input)
  const { i18n } = useLingui()

  const handleSearch = () => {
    const customer = input.customer.trim()

    url.searchParams.set('customer', customer)
    window.history.replaceState(null, '', url)
    setState({ customer })
  }

  return (
    <>
      <Flex bg="primary.50" alignItems="center">
        <Input
          bg="white"
          m="2"
          placeholder={t`Customer phone number`}
          {...setInput.customer}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleSearch()
          }}
        />

        <Select
          width="200px"
          bg="white"
          value={i18n.locale}
          onChange={(e) => {
            i18n.activate(e.currentTarget.value)
          }}
        >
          <option value="en">English</option>
          <option value="da">Dansk</option>
          <option value="no">Norsk</option>
          <option value="se">Svenska</option>
        </Select>

        <Button
          colorScheme="primary"
          mx="2"
          width="120px"
          size="md"
          onClick={handleSearch}
        >
          Go
        </Button>
      </Flex>

      {token ? (
        <Widget
          apiToken={token}
          filter={{
            customer: state.customer,
            customers: state.customer,
          }}
          sessionId={sessionId}
        />
      ) : (
        <ErrorComponent error={new Error(t`Missing api token`)} />
      )}
    </>
  )
}

export default TestLayer
