import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Text,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { usePostHog } from 'posthog-js/react'

import React, { useEffect, useRef, useState } from 'react'
import {
  MdKeyboardArrowUp,
  MdOutlineThumbDown,
  MdOutlineThumbUp,
} from 'react-icons/md'

type Props = {
  customSummary: string
  rootCause?: string
  conversationUid: string
}
const MAX_SUMMARY_HEIGHT = 190

const SummaryBox: React.FC<Props> = ({
  customSummary,
  rootCause,
  conversationUid,
}) => {
  const [show, setShow] = React.useState(false)
  const ref = useRef<HTMLParagraphElement>(null)
  const [height, setHeight] = useState(0)
  const forceShow = MAX_SUMMARY_HEIGHT > (ref.current?.offsetHeight || 0)

  const toast = useToast()
  const [isReviewed, setIsReviewed] = useState(false)
  const posthog = usePostHog()

  const handleToggle = (): void => {
    setHeight(ref.current?.offsetHeight || MAX_SUMMARY_HEIGHT)
    setShow((s) => !s)
  }
  useEffect(() => {
    setHeight(ref.current?.offsetHeight || MAX_SUMMARY_HEIGHT)
  }, [])

  return (
    <>
      <Box
        onClick={handleToggle}
        background="white"
        borderColor="gray.200"
        position="relative"
        overflow="hidden"
        borderRadius="0 0 8px 8px"
        w="100%"
        cursor={forceShow ? undefined : 'pointer'}
        _hover={{
          background: forceShow ? undefined : 'gray.200',
        }}
      >
        <Flex
          alignItems={'start'}
          gap={1}
          px={4}
          py={2}
          pb={forceShow || show ? 2 : 0}
          w="100%"
        >
          <Box w="100%">
            <Box
              transition="height 200ms"
              height={forceShow || show ? height : MAX_SUMMARY_HEIGHT}
              overflow="hidden"
            >
              <HStack align="flex-start">
                <Flex gap="3" flexDir="column" ref={ref}>
                  <Box>
                    <Text color="gray.600" fontSize="sm" fontWeight="500">
                      <Trans>Call reason description</Trans>
                    </Text>
                    <Text
                      color="gray.800"
                      fontWeight="300"
                      whiteSpace="break-spaces"
                    >
                      {rootCause}
                    </Text>
                  </Box>
                  <Box>
                    <Text color="gray.600" fontSize="sm" fontWeight="500">
                      <Trans>Additional details</Trans>
                    </Text>
                    <Text
                      color="gray.800"
                      fontWeight="300"
                      whiteSpace="break-spaces"
                    >
                      {customSummary}
                    </Text>
                  </Box>
                </Flex>
                {!isReviewed && (
                  <HStack gap="1">
                    <IconButton
                      aria-label="Thumbs up"
                      icon={<MdOutlineThumbUp />}
                      size="xs"
                      variant="ghost"
                      color="gray.500"
                      onClick={(e) => {
                        e.stopPropagation()
                        posthog?.capture('widgetsummary_ThumbsUp', {
                          summary: customSummary,
                          rootCause: rootCause,
                          conversationUid: conversationUid,
                        })
                        setIsReviewed(true)
                        toast({
                          title: t`Thank you for the feedback`,
                          description: t`You are helping us to improve future summaries`,
                          status: 'success',
                          duration: 4000,
                          isClosable: true,
                        })
                      }}
                    />
                    <IconButton
                      aria-label="Thumbs down"
                      fontSize="8px"
                      icon={<MdOutlineThumbDown />}
                      size="xs"
                      variant="ghost"
                      color="gray.500"
                      onClick={(e) => {
                        e.stopPropagation()
                        posthog?.capture('widgetsummary_ThumbsDown', {
                          summary: customSummary,
                          rootCause: rootCause,
                          conversationUid: conversationUid,
                        })
                        setIsReviewed(true)
                        toast({
                          title: t`Thank you for the feedback`,
                          description: t`You are helping us to improve future summaries`,
                          status: 'success',
                          duration: 4000,
                          isClosable: true,
                        })
                      }}
                    />
                  </HStack>
                )}
              </HStack>
            </Box>
          </Box>
        </Flex>
        {!forceShow && (
          <Box
            transition="height 300ms"
            id="gradientBox"
            pos={show ? 'static' : 'absolute'}
            bottom="0px"
            w="100%"
            h={show ? '12px' : '50px'}
            background={
              show
                ? 'none'
                : 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 100%)'
            }
          >
            <Icon
              transition="transform 500ms"
              transform={show ? 'scale(1, 1)' : 'scale(1, -1)'}
              pos="absolute"
              left="50%"
              bottom="4px"
              as={MdKeyboardArrowUp}
              fontSize="20px"
            />
          </Box>
        )}
      </Box>
    </>
  )
}

export default SummaryBox
