import { UseMutationResult, useMutation } from '@tanstack/react-query'
import ky from 'ky'
import qs from 'query-string'

import { API_PREFIX } from '../../config'
import { RequestError, extractHttpError } from './extractHttpError'
import { log } from './log'

export const useLiveTranscriptionSummary = (): UseMutationResult<
  string | undefined,
  RequestError,
  { apiToken: string; sessionId: string }
> =>
  useMutation({
    mutationKey: ['voice bot transcription summary'],
    mutationFn: async ({
      apiToken,
      sessionId,
    }: { apiToken: string; sessionId: string }) => {
      try {
        return await ky
          .get(
            qs.stringifyUrl({
              url: `${API_PREFIX}/puzzel-widget/summarize-live-transcript`,
              query: {
                date: new Date().toJSON(),
                session_id: sessionId,
              },
            }),
            {
              credentials: 'include',
              mode: 'cors',
              headers: {
                Authorization: apiToken,
              },
            },
          )
          .json<{ summary: string }>()
          .then((d) => d.summary)
      } catch (error) {
        const e = await extractHttpError(error)
        log(apiToken, {
          data: { name: e.name, message: e.message, ...e.cause },
          level: 'error',
          message: 'useConversations error',
        })

        throw e
      }
    },
  })
